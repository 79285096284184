import { render, staticRenderFns } from "./sceneryCategory.vue?vue&type=template&id=e35278b2&scoped=true&"
import script from "./sceneryCategory.vue?vue&type=script&lang=js&"
export * from "./sceneryCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e35278b2",
  null
  
)

component.options.__file = "sceneryCategory.vue"
export default component.exports